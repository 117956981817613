import React from "react";

import Image1 from "../../assets/images/blog/1.jpg";
import Image2 from "../../assets/images/blog/2.jpg";
import Image3 from "../../assets/images/blog/3.jpg";
import Image4 from "../../assets/images/blog/4.jpg";
import Image5 from "../../assets/images/blog/5.jpg";
import Image6 from "../../assets/images/blog/6.jpg";

export default function Blog() {
  const blog = [
    {
      id: "1",
      title: "Verzeker je fiets en vertrek zorgeloos",
      description:
        "Met de fietsverzekering van DVV krijg je een schadevergoeding als je fiets gestolen of beschadigd is.",
      image: Image1,
      url: "https://www.dvv.be/nl/verzekeringen/fiets-en-motor/fietsverzekering.html"
    },
    {
      id: "2",
      title: "Reisbijstand in België en het buitenland",
      description:
        "Vertrek gerust op reis dankzij de personenbijstand met onbeperkte terugbetaling van de medische kosten in het buitenland en de repatriëring.",
      image: Image2,
      url: "https://www.dvv.be/nl/verzekeringen/reizen/reisbijstand.html"
    },
    {
      id: "3",
      title: "Vermijd de files aan de péages",
      description:
        "Vertrek je met de wagen naar Frankrijk, Spanje, Italië of Portugal? Met een tolbadge vermijd je op de autosnelwegen de files aan de péages.",
      image: Image3,
      url: "https://www.dvv.be/nl/verzekeringen/auto/artikelen/vermijd-de-files-aan-de-peages.html"
    },
    {
      id: "1",
      title: "Wanneer je belastingaangifte indienen in 2023?",
      description:
        "Dit jaar (2023) zijn er een aantal wijzigingen in de aangifte van de personenbelasting (inkomsten 2022), onder andere de uiterste datum voor de indiening.",
      image: Image4,
      url: "https://www.dvv.be/nl/verzekeringen/gezin/artikelen/wanneer-je-belastingaangifte-indienen.html"
    },
    {
      id: "2",
      title: "Is mijn rijbewijs nog geldig?",
      description:
        "In België bestaan twee formaten van rijbewijs. Het oude model in drie luiken op roos papier en het nieuwe rijbewijs met het formaat van een bankkaart. ",
      image: Image5,
      url: "https://www.dvv.be/nl/verzekeringen/auto/artikelen/is-mijn-rijbewijs-nog-geldig.html"
    },
    {
      id: "3",
      title: "Woning kopen: 9 stappen",
      description:
        "Een huis of appartement kopen doe je niet elke dag. Het gaat om een belangrijk moment in je leven. Maar wat komt er allemaal kijken bij een woning kopen?",
      image: Image6,
      url: "https://www.dvv.be/nl/verzekeringen/kredieten/artikelen/woning-kopen-9-stappen.html"
    },
  ];

  return (
    <>
      <section className="relative md:py-24 py-16" id="blog">
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-burms-dark-blue text-base font-medium uppercase mb-2">Blog</h6>
            <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Laatste Nieuws</h3>

            <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Hier bieden we inzichtelijke artikelen en nuttige tips om u te helpen bij het navigeren door het landschap van verzekeringen</p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6 mt-8">
            {blog.map((item, key) => (
              <div key={key} className="blog relative rounded-md shadow shadow-slate-200 dark:shadow-slate-800 overflow-hidden">
                <img src={item.image} alt="" />
                <div className="content p-6">
                <a href={item.url} target="_blank" rel="noopener noreferrer" className="text-lg hover:text-burms-light-blue dark:text-white dark:hover:text-burms-light-blue transition-all duration-500 ease-in-out font-medium">{item.title}</a>

                  <p className="text-slate-400 mt-3">{item.description}</p>

                  <div className="mt-5">
                  <a href={item.url} target="_blank" rel="noopener noreferrer" className="text-lg hover:text-burms-light-blue dark:text-white dark:hover:text-burms-light-blue transition-all duration-500 ease-in-out font-medium">Lees Meer</a>

                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );

}

