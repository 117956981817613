export default function Contact() {
    return (
        <>
            {/* Start */}
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="contact">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h6 className="text-burms-dark-blue text-base font-medium uppercase mb-2">Contacteer ons</h6>
                        <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white"></h3>
                        <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">U bent van harte welkom om ons te bezoeken voor een verhelderend gesprek onder het genot van een kopje koffie.</p>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-6">
                        <div className="lg:col-span-8">
                            <div className="p-6 rounded-md shadow bg-white dark:bg-slate-900">
                                <form>
                                    <div className="grid lg:grid-cols-12 lg:gap-6">
                                        <div className="lg:col-span-6 mb-5">
                                            <input name="name" id="name" type="text" className="form-input" placeholder="Naam :" />
                                        </div>
                                        <div className="lg:col-span-6 mb-5">
                                            <input name="email" id="email" type="email" className="form-input" placeholder="Email :" />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1">
                                        <div className="mb-5">
                                            <input name="subject" id="subject" className="form-input" placeholder="Onderwerp :" />
                                        </div>
                                        <div className="mb-5">
                                            <textarea name="comments" id="comments" className="form-input textarea h-28" placeholder="Bericht :"></textarea>
                                        </div>
                                    </div>
                                    <button type="submit" id="submit" name="send" className="btn bg-burms-dark-blue hover:bg-burms-light-blue border-burms-dark-blue hover:border-burms-light-blue text-white rounded-md h-11 justify-center flex items-center">Verstuur</button>
                                </form>
                            </div>
                        </div>

                        <div className="lg:col-span-4">
                            <div className="lg:ms-8">
                            <div className="flex">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-clock block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white text-burms-dark-blue mb-2 font-medium">Openinguren</h5>
                                        <a href="tel:+152534-468-854" className="text-burms-dark-blue"><strong>Ma</strong> 09.00 - 12.00 </a>  
                                        <a href="tel:+152534-468-854" className="text-burms-dark-blue">- 14.00 - 19.00 </a>
                                        <br />
                                        <a href="tel:+152534-468-854" className="text-burms-dark-blue"><strong>Di</strong>  09.00 - 12.00 </a>  
                                        <a href="tel:+152534-468-854" className="text-burms-dark-blue">- 14.00 - 19.00 </a>
                                        <br />
                                        <a href="tel:+152534-468-854" className="text-burms-dark-blue"><strong>Wo</strong> 09.00 - 12.00 </a>
                                        <br />
                                        <a href="tel:+152534-468-854" className="text-burms-dark-blue"><strong>Do</strong> 09.00 - 12.00 </a>  
                                        <a href="tel:+152534-468-854" className="text-burms-dark-blue">- 14.00 - 19.00 </a>
                                        <br />
                                        <a href="tel:+152534-468-854" className="text-burms-dark-blue"><strong>Vr</strong> 09.00 - 12.00</a>
                                        <br />
                                        <a href="tel:+152534-468-854" className="text-burms-dark-blue"><strong>Za</strong> Gesloten</a>
                                        <br />
                                        <a href="tel:+152534-468-854" className="text-burms-dark-blue"><strong>Zo</strong> Gesloten</a>
                                    </div>
                                </div>
                                <div className="flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-phone block rounded text-2xl dark:text-white text-burms-dark-blue mb-0"></i>
                                    </div>
                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white text-burms-dark-blue mb-2 font-medium">Telefoon</h5>
                                        <a href="tel:+152534-468-854" className="text-burms-dark-blue">+ 32 3 6644350</a>
                                    </div>
                                </div>
                                <div className="flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-envelope block rounded text-2xl dark:text-white text-burms-dark-blue  mb-0"></i>
                                    </div>
                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white text-burms-dark-blue mb-2 font-medium">Email</h5>
                                        <a href="mailto:contact@example.com" className="text-burms-dark-blue">burms@dvv.be</a>
                                    </div>
                                </div>
                                <div className="flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-map-marker block rounded text-2xl dark:text-white text-burms-dark-blue mb-0"></i>
                                    </div>
                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white text-burms-dark-blue mb-2 font-medium">Location</h5>
                                        <p className="text-burms-dark-blue mb-2">Klein Heiken 19
2180 EKEREN (ANTW.)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}