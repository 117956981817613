//REACTS
import React, { useState } from "react";
import { Link } from "react-scroll";
import { Link as Link2 } from "react-router-dom";

//ASSETS
import LogoLight from "../assets/images/logo-light.png";
import LogoDark from "../assets/images/logo-dark.png";
import Typed from "typed.js";
import BackgroudImage from "../assets/images/bg/burms_verzekeringen_header.png";

//COMPONENTS 
import Switcher from "../component/components/Switcher";
import Footer from "../component/components/Footer";

//PAGES
import Services from "../component/pages/Services";
import About from "../component/pages/About";
import Contact from "../component/pages/Contact";
import Blog from "../component/pages/Blog";
import Mobility from "../component/pages/Mobility";

//HULP MENU PAGES
import Stormschade from "../component/pages/Mydvv/Stormschade";
import Prefooter from "../component/Prefooter"



/**
 * Index Component
 */
export default function Index() {

  const [isOpen, setMenu] = useState(true);
  
  window.addEventListener("scroll", windowScroll);

  const toggleMenu = () => {
    setMenu(!isOpen)
  }

  setTimeout(() => {
    new Typed("#typed", {
      strings: [
        "<b>Brandverzekering?</b>",
        "<b>Autoverzekering?</b>",
        "<b>Woonkrediet?</b>",
        "<b>Reisbijstand?</b>"
       
      ],
      backDelay: 2000,
      loop: true,
      startDelay: 300,
      typeSpeed: 100,
      backSpeed: 100,
    });
  }, 500);


  /**
   * Window scroll
   */
  function windowScroll() {
    const navbar = document.getElementById("navbar");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      navbar.classList.add("is-sticky");
    } else {
      navbar.classList.remove("is-sticky");
    }
  }

  return (
    <>
      <div>
        <nav className="navbar" id="navbar">
          <div className="container flex flex-wrap items-center justify-end">
            <a className="navbar-brand" href="index.html">
              <span className="inline-block dark:hidden">
                <img src={LogoDark} className="l-dark" alt="" />
                <img src={LogoLight} className="l-light" alt="" />
              </span>
              <img
                src={LogoLight}
                className="hidden dark:inline-block"
                alt=""
              />
            </a>

            <div className="nav-icons flex items-center lg_992:order-2 ms-auto">
              <ul className="list-none menu-social mb-0">
                <li className="inline ms-1 cursor-pointer">
                  <Link2 to="https://my.dvv.be/nl">
                    <span className="login-btn-primary">
                      <span className="btn btn-lg rounded bg-dvv-orange hover:bg-burms-light-blue border-dvv-orange hover:border-burms-light-blue text-white">
                        <p className="uil-angle-down"> MyDVV</p>
                        
                        
                      </span>
                    </span>
                    </Link2>
                    <span className="login-btn-light">
                      
                      <span className="btn btn-lg group rounded bg-burms-dark-blue hover:bg-dvv-orange border-burms-dark-blue hover:border-dvv-orange text-white">
                      
                        <p className="uil-angle-down"> MyDVV</p>   
                        <div className="  text-left rounded-md invisible absolute z-50 flex  flex-col bg-gray-100 py-5 px-6 text-gray-800 shadow-xl group-hover:visible">
                            <Link to="Stormschade"><a className=" my-2  text-gray-500 hover:text-black" >Aangifte Stormschade</a> </Link>
                            <Link to="Stormschade"><a className=" my-2  text-gray-500 hover:text-black" >Aangifte Hagelschade</a> </Link>
                            <Link to="Stormschade"><a className=" my-2  text-gray-500 hover:text-black" >Toevoegen Bestuurder</a> </Link>
                            <Link to="Stormschade"><a className=" my-2  text-gray-500 hover:text-black" >Aangifte Auto/Moto</a> </Link>
                            <Link to="Stormschade"><a className=" my-2  text-gray-500 hover:text-black" >Offerte Auto</a> </Link>
                        </div>
                      </span>
                    </span>
                </li>
                
              </ul>
              <button
                type="button"
                className="collapse-btn inline-flex items-center ms-3 text-dark dark:text-white lg_992:hidden"
                onClick={toggleMenu}
              >
                <span className="sr-only">Navigation Menu</span>
                <i className="mdi mdi-menu mdi-24px"></i>
              </button>
            </div>

            <div
              className={`${isOpen === true ? 'hidden' : 'block'} navigation lg_992:order-1 lg_992:flex`}
              id="menu-collapse"
            >
              <ul className="navbar-nav nav-light" id="navbar-navlist">
                <Link
                  className="nav-item"
                  to="home"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">Home</span>
                </Link>
                <Link
                  className="nav-item"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  to="about"
                >
                  <span className="nav-link">Wie zijn wij?</span>
                </Link>
                <Link
                  className="nav-item"
                  to="features"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">Familie & Gezondheid</span>
                </Link>
                <Link
                  className="nav-item"
                  to="mobility"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">Mobiliteit</span>
                </Link>
                <Link
                  className="nav-item"
                  to="blog"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">Nieuws</span>
                </Link>
                <Link
                  className="nav-item"
                  to="contact"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">Contact</span>
                </Link>
                <Link
                  className="nav-item"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                </Link>
              </ul>
            </div>
          </div>
        </nav>
        <section
          style={{ backgroundImage: `url(${BackgroudImage})` }}
          className="py-36 lg:py-64 w-full table relative bg-center bg-cover"
          id="home"
        >
          <div className="absolute inset-0 bg-black opacity-20"></div>
          <div className="container relative">
            <div className="grid grid-cols-1 mt-12">
              <h5 className="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">
                Opzoek naar een<br />
                {" "}
                <span
                  className="typewrite relative text-type-element"
                  id="typed"
                  data-period="2000"
                  data-type='[ "Business", "Startups", "Digital Agency", "Marketing" ]'
                ></span>
              </h5>

              <p className="text-white opacity-50 mb-0 max-w-2xl text-lg">
              We bieden een breed scala aan op maat gemaakte verzekeringsoplossingen, van auto tot leven, 
              brand tot hospitalisatie. Met een toegewijd team van experts streven zij ernaar onze klanten de best mogelijke dekking te bieden.
              </p>

              <div className="relative mt-10">
                <Link2
                  to="https://www.dvv.be/nl/consulenten/burms-verzek-bv-99420535.html"
                  className="btn bg-burms-light-blue hover:bg-burms-dark-blue border-burms-light-blue hover:border-burms-dark-blue text-white rounded-md"
                >
                  Afspraak maken
                </Link2>
              </div>
            </div>
          </div>
        </section>

        {/* About section */}
        <About />

        {/* Service section */}
        <Services />

        {/* Portfolio section */}
        
        

        {/* Pricing section */}

    

        {/* Blog section */}

         {/* Mobility section */}

        <Mobility />
        
        <Blog />

        {/* Contact section */}
        <Contact />

        {/* Footer section */}
        <Prefooter />
        <Footer />

        {/* Switcher section */}
        <Switcher />
      </div>

    </>
  );

}

