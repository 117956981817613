import React, { useState } from "react";


import AboutImage from "../../assets/images/about.jpg";
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";

export default function About() {
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="about">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
            <div className="lg:col-span-5">
              <div className="relative">
                <img src={AboutImage} className="rounded-lg shadow-lg relative" alt="" />
                <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                  
                </div>
              </div>
            </div>
            {/* end col */}

            <div className="lg:col-span-7">
              <div className="lg:ms-7">
                <h6 className="text-burms-dark-blue text-base font-medium uppercase mb-2">Wie zijn we ?</h6>
                <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Burms Verzekeringen - Veilig, Vertrouwd, Verzekerd</h3>

                <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">Of u particulier, zelfstandig, bedrijfsleider of verantwoordelijke van een vereniging bent: u kan er vanop aan dat u bij ons altijd de producten vindt die u nodig hebt. Ook voor een persoonlijke service kan u bij ons terecht. 
                Wij staan voor u klaar in ons kantoor te Ekeren (Antwerpen). Bij ons staat het menselijke aspect voorop: bij ons bent u een klant, geen dossier.</p>

                <div className="relative mt-10">
                  <a href="https://www.dvv.be/nl/consulenten/burms-verzek-bv-99420535.html#!" className="btn bg-burms-light-blue hover:bg-burms-dark-blue border-burms-light-blue hover:border-burms-dark-blue text-white rounded-md">Instellen als mijn consulent</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End --> */}
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="yba7hPeTSjk"
        onClose={() => setOpen(false)}
      />
    </>
  );

}

