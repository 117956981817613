import React from "react";
import { Link } from "react-router-dom";

import LogoLight from "../assets/images/logo-light.png";
import DvvLogo from "../assets/images/dvv-verzekeringen.png"
import PrivacyPdf from "../assets/PrivacyVerklaring.pdf"
import VisionPdf from "../assets/Vision.pdf"

export default function Footer() {

    return (
        <>
            {/*  Start Footer  */}

            <div className="py-1 bg-dvv-blue"></div>
            <footer className="py-8 bg-dvv-grey dark:bg-burms-dark-blue">
            <div className="container items-center">
            <div className="grid md:grid-cols-12 items-left">
                <div className="md:col-span-3  mt-8 text-slate-400">
                    © 2023 Burms verzekeringen RPR ANTWERPEN 0430.954.568 VERBONDEN AGENT VOOR DVV verzekeringen - belins NV <br/> FSMA: 031542cA
                </div>

            <div className="md:col-span-3  mt-8">
                <div className="text-left text-slate-400">
                DVV - Burms Verzek. BV <br /> Klein Heiken 19 <br />2180 EKEREN <br /> + 32 3 6644350
                </div>
            </div>           
            <div className="md:col-span-3  mt-8 text-dvv-orange">
                <a href="https://my.dvv.be/nl">Je online dossier</a> <br/>
                <a 
                   href={PrivacyPdf}
                   download="Privacy Verklaring"
                   target="_blank"
                   rel="noopener noreferrer">Privacy Charter</a> <br/>
                <a 
                   href={VisionPdf}
                   download="Onze Visie"
                   target="_blank"
                   rel="noopener noreferrer">Onze Visie</a> <br/>
                                                
             
            </div>
            <div className="md:col-span-3 md:mt-0 mt-8">
                    <Link to="#" className="logo-footer">
                        <img src={DvvLogo} className="md:ms-0 mx-auto" alt="" />
                    </Link>
            </div>
        </div>
    </div>
</footer>

            {/* <!-- End Footer --> */}

            {/* <!-- Back to top --> */}
            <Link to="home" id="back-to-top" className="back-to-top fixed hidden text-lg rounded-full z-10 bottom-5 end-5 h-9 w-9 text-center bg-burms-dark-blue text-white leading-9"><i className="uil uil-arrow-up"></i></Link>
            {/* <!-- Back to top --> */}
        </>
    );

}

