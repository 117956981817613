import React, { useState, useEffect } from "react";

//TAILWIND CSS 
import './assets/css/tailwind.css';
import './assets/scss/tailwind.scss';

//ASSETS
import './assets/scss/icons.scss';

import {
  Route,
  Routes
} from "react-router-dom";


import Index from "./pages/index.js";

import Login from './pages/auth/login';
import Signup from './pages/auth/signup';
import ResetPassword from './pages/auth/reset-password';
import Loader from "./component/components/Loader";

import Stormschade from "./component/pages/Mydvv/Stormschade";

export default function App() {

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.documentElement.setAttribute('dir', 'ltr');
    handleRouteChange();
  }, []);


  // Route change method
  const handleRouteChange = () => {
    setLoading(false);
  }

  return (
    <>
      {loading && <Loader />}
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/index" element={<Index />} />
        <Route path="/auth-login" element={<Login />} />
        <Route path="/auth-signup" element={<Signup />} />
        <Route path="/auth-reset-password" element={<ResetPassword />} />

        <Route path="/Stormschade" element={<Stormschade/>}/>
      </Routes>
    </>
  );

}
