import React from "react";
import { Link } from "react-router-dom";


export default function Mobility() {
  
  const team = [
    {
      title: 'Auto',
      icon: 'car',
      subtext: 'Autoverzekering Mobility: Burgerlijke Aansprakelijkheid - Mini & Full Omnium',
      url: "https://www.dvv.be/nl/verzekeringen/auto.html"
    },
    {
      title: 'Moto & Scooter',
      icon: 'dashboard',
      subtext: 'Vind de verzekering voor je (elektrische) fiets, motor of brommer',
      url: "https://www.dvv.be/nl/verzekeringen/fiets-en-motor.html"

    },
    {
      title: 'Elektrische Fiets',
      icon: 'plug',
      subtext: 'Heb je geïnvesteerd in een elektrische fiets of racefiets? Verzeker je fiets en rij met een gerust geweten.',
      url: "https://www.dvv.be/nl/verzekeringen/fiets-en-motor/fietsverzekering.html"
    },
    {
      title: 'Camper',
      icon: 'luggage-cart',
      subtext: 'Met een camperverzekering ben je gerust dat je mobilhome goed en volledig verzekerd is.',
      url: "https://www.dvv.be/nl/verzekeringen/auto/ba-omnium-camper.html"
    },
    {
      title: 'Reizen',
      icon: 'plane-fly',
      subtext: 'Vertrek gerust op reis met een reisverzekering op jouw maat',
      url: "https://www.dvv.be/nl/verzekeringen/reizen.html"
    },
    {
      title: 'Rechtsbijstand verkeer',
      icon: 'balance-scale',
      subtext: 'Met de verzekering Lex.Tra Verkeer ben je zeker van rechtsbijstand voor alle schadegevallen in het verkeer.',
      url: "https://www.dvv.be/nl/verzekeringen/rechtsbijstand/verkeer.html"
    }
  ]

  return (
    <>
      {/* Start */}
      <section className="relative md:py-24 py-16 active" id="mobility">
        <div className="container lg mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 pb-8 items-center">
            <div>
              <h6 className="text-burms-dark-blue text-base font-medium uppercase mb-2">Mobiliteit</h6>
              <h5 className="mb-4 md:text-2xl text-xl font-semibold dark:text-white md:mb-0">Zorgeloos Onderweg: Bescherming Voor Uw Mobiliteit </h5>
            </div>

            <div>
              <p className="text-slate-400 dark:text-slate-300 max-w-xl"></p>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">

            {team.map((item, key) => (
              <div key={key} className={`features p-6 ${key % 2 === 0 ? "hover:shadow-xl hover:shadow-slate-100 dark:hover:shadow-slate-800" : "shadow-xl shadow-slate-100 dark:shadow-slate-800"} transition duration-500 rounded-3xl mt-8`}>
                <div className="w-20 h-20 bg-orange-600/5 text-burms-dark-blue rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm">
                  <i className={`uil uil-${item.icon}`}></i>
                </div>

                <div className="content mt-7">
                  <Link to="#" className="text-lg hover:text-burms-dark-blue dark:text-white dark:hover:text-burms-dark-blue transition-all duration-500 ease-in-out font-medium">{item.title}</Link>
                  <p className="text-slate-400 mt-3">{item.subtext}</p>

                  <div className="mt-5">
                    <a href={item.url} target="_blank" rel="noopener noreferrer" className="text-lg hover:text-burms-light-blue dark:text-white dark:hover:text-burms-light-blue transition-all duration-500 ease-in-out font-light">Lees Meer<i className="uil uil-arrow-right"></i></a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        
      </section>
    </>
  );

}
