import React from "react";
import { Link } from "react-router-dom";

import LogoLight from "../../assets/images/logo-light.png";

export default function Footer() {

    return (
        <>
            {/*  Start Footer  */}
            <footer className="py-2 bg-dvv-blue dark:bg-dvv-blue">
                <div className="container">
                    <div className="grid md:grid-cols-12 items-center">
                        <div className="md:col-span-3">
                          
                        </div>

                        <div className="md:col-span-6 md:mt-0 mt-8">
                            <div className="text-center">
                                <p className="text-white">© {(new Date().getFullYear())}{" "} Burms verzekeringen BV. Developed with <i className="mdi mdi-heart text-orange-700"></i> by <Link to="https://kaizenprojects.be/" target="_blank" className="text-reset">Kaizen Projects</Link>.</p>
                            </div>
                        </div>

                        <div className="md:col-span-4 md:mt-0 mt-8">
                            
                        </div>
                    </div>
                </div>
            </footer>
            {/* <!-- End Footer --> */}

            {/* <!-- Back to top --> */}
            <Link to="home" id="back-to-top" className="back-to-top fixed hidden text-lg rounded-full z-10 bottom-5 end-5 h-9 w-9 text-center bg-burms-dark-blue text-white leading-9"><i className="uil uil-arrow-up"></i></Link>
            {/* <!-- Back to top --> */}
        </>
    );

}