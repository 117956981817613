import React from "react";
import { Link } from "react-router-dom";

export default function Services() {
  const team = [
    {
      title: 'Familiale verzekering',
      icon: 'users-alt',
      subtext: 'Verzeker de schade die je gezin toebrengt aan derden dankzij de BA familiale verzekering'
    },
    {
      title: 'Hospitalisatieverzekering',
      icon: 'hospital',
      subtext: 'Vermijd kosten voor medische verzorging en hospitalisatie die niet vergoed worden door de mutualiteit'
    },
    {
      title: 'Lichamelijke ongevallen',
      icon: 'band-aid',
      subtext: 'Een verzekering voor de dagelijkse ongevallen op maat van je noden'
    },
    {
      title: 'Huispersoneel & activiteiten',
      icon: 'house-user',
      subtext: 'Verzeker je poetsvrouw of tuinman met verzekering voor Arbeidsongevallen Huispersoneel'
    },
    {
      title: 'Overlijden',
      icon: 'heart-rate',
      subtext: 'Zorg voor je nabestaanden met een overlijdens- of uitvaartverzekering'
    },
    {
      title: 'Rechtsbijstand gezin',
      icon: 'briefcase-alt',
      subtext: 'Zorg ervoor dat je rechten verdedigd worden bij privégeschillen.'
    }
  ]

  return (
    <>
      {/* Start */}
      <section className="relative md:py-24 py-16 active" id="features">
        <div className="container lg mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 pb-8 items-center">
            <div>
              <h6 className="text-burms-dark-blue text-base font-medium uppercase mb-2">Familie & gezondheid</h6>
              <h3 className="mb-4 md:text-2xl text-xl font-semibold dark:text-white md:mb-0">De perfecte oplossing voor heel je <br /> gezin</h3>
            </div>

            <div>
              <p className="text-slate-400 dark:text-slate-300 max-w-xl"></p>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">

            {team.map((item, key) => (
              <div key={key} className={`features p-6 ${key % 2 === 0 ? "hover:shadow-xl hover:shadow-slate-100 dark:hover:shadow-slate-800" : "shadow-xl shadow-slate-100 dark:shadow-slate-800"} transition duration-500 rounded-3xl mt-8`}>
                <div className="w-20 h-20 bg-orange-600/5 text-burms-dark-blue rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm">
                  <i className={`uil uil-${item.icon}`}></i>
                </div>

                <div className="content mt-7">
                  <Link to="#" className="text-lg hover:text-burms-dark-blue dark:text-white dark:hover:text-burms-dark-blue transition-all duration-500 ease-in-out font-medium">{item.title}</Link>
                  <p className="text-slate-400 mt-3">{item.subtext}</p>

                  <div className="mt-5">
                    <Link to="https://www.dvv.be/nl/verzekeringen/familie-en-gezondheid.html" className="btn btn-link hover:text-burms-light-blue dark:hover:text-burms-light-blue after:bg-burms-light-blue dark:text-white transition duration-500">Lees Meer <i className="uil uil-arrow-right"></i></Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="container md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-burms-dark-blue text-base font-medium uppercase mb-2">Plan van aanpak</h6>
            <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Hieronder vindt u onze gestructureerde werkwijze</h3>

            <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto"></p>
          </div>

          <div className="grid grid-cols-1 mt-8">
            <div className="timeline relative">
              <div className="timeline-item">
                <div className="grid sm:grid-cols-2">
                  <div className="">
                    <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                      
                    </div>
                  </div>
                  <div className="mt-4 md:mt-0">
                    <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">Beoordeling van Behoeften</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">Dit begint met een diepgaande analyse van uw persoonlijke situatie en verzekeringsbehoeften. Onze verzekeringsdeskundigen zullen u vragen stellen over uw levensstijl, 
                      financiën, gezondheid en toekomstplannen om te bepalen welke soorten verzekeringen voor u het meest geschikt zijn.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timeline-item mt-5 pt-4">
                <div className="grid sm:grid-cols-2">
                  <div className="md:order-1 order-2">
                    <div className="event event-description-left ltr:float-left rtl:float-right ltr:text-right rtl:text-left md:me-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">Selectie van de Juiste Verzekering</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">Op basis van de verzamelde informatie bieden we u een reeks verzekeringsproducten die bij uw behoeften passen. We zullen elke optie gedetailleerd bespreken, 
                      inclusief de dekking, premies en voorwaarden, zodat u een geïnformeerde beslissing kunt nemen.</p>
                    </div>
                  </div>
                  <div className="md:order-2 order-1">
                    <div className="duration duration-right md:ms-7 relative">
                      
                    </div>
                  </div>
                </div>
              </div>

              <div className="timeline-item mt-5 pt-4">
                <div className="grid sm:grid-cols-2">
                  <div className="mt-4 mt-sm-0">
                    <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                     
                    </div>
                  </div>
                  <div className="mt-4 mt-sm-0">
                    <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">Afsluiten van de Verzekering</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">Zodra u de beste verzekering heeft gekozen, zullen we u helpen bij het invullen en indienen van de nodige documenten om uw polis af te sluiten. We zullen ervoor zorgen dat u alle belangrijke informatie begrijpt, zoals uw rechten en plichten, 
                      de betalingsprocedures en hoe u een claim kunt indienen.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );

}
